<script>
/***
 Name: edit_item
 Code: sa0ChunLuyu
 Time: 2021/11/5 10:06
 Remark: 编辑字段
 */
export default {
  props: ['info', 'reload', 'create_do'],
  data() {
    return {
      item_content: [],
      replace_show: 'ABCDEFG',
      item_input: '',
      edit_info: {
        id: 0,
        name: "",
        pid: 0,
        type: 0,
        content: "",
        content_turn: "",
        is_del: 2,
        is_privacy: 2,
        left_reserved: 0,
        right_reserved: 0,
        replace: '*',
        replace_type: 1,
      }
    }
  },
  watch: {
    info: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo()
    console.log(333, this.edit_info)
  },
  computed: {
    replaceShow() {
      let ret = this.replace_show;
      let left_reserved = Number(this.edit_info.left_reserved)
      let right_reserved = Number(this.edit_info.right_reserved)
      let replace_type = this.edit_info.replace_type
      let replace = this.edit_info.replace

      let left = ret.substring(0, Number(this.edit_info.left_reserved));
      let right = ret.substring(ret.length - right_reserved, ret.length);
      if (right_reserved >= ret.length - left_reserved) {
        right = '';
      }
      let rep = ''
      let l = ret.length - left.length - right.length
      let len = l >= 0 ? l : 0;
      if (replace_type === 1) {
        rep = replace;
        if (len === 0) rep = ''
      } else {
        for (let i = 0; i < len; i++) {
          rep += replace.toString()
        }
      }
      return `${left}${rep}${right}`;
    },
  },
  methods: {
    mountedDo() {
      this.edit_info = JSON.parse(JSON.stringify(this.info))
      if (this.edit_info.type === 2
          || this.edit_info.type === 3
          || this.edit_info.type === 4) {
        this.item_content = JSON.parse(this.edit_info.content);
      }
    },
    createDo(type) {
      let content = this.edit_info.content
      console.log(this.edit_info.type)
      if (this.edit_info.type === 2
          || this.edit_info.type === 3
          || this.edit_info.type === 4) {
        content = JSON.stringify(this.item_content)
      }
      if (this.edit_info.type === 7) {
        content = this.edit_info.content ? this.edit_info.content : '0'
      }
      this.$sa0.post({
        url: this.$api('创建委托单字段'),
        data: {
          name: this.edit_info.name,
          pid: this.edit_info.pid,
          type: this.edit_info.type,
          content: content,
          is_privacy: this.edit_info.is_privacy,
          left_reserved: this.edit_info.left_reserved,
          right_reserved: this.edit_info.right_reserved,
          replace: this.edit_info.replace,
          replace_type: this.edit_info.replace_type,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg(response.message)
            if (type === 1) {
              this.$router.push({
                params: {
                  id: response.data.id
                },
                query: {
                  edit_type: 0
                }
              })
            } else {
              this.edit_info = {
                id: 0,
                name: "",
                pid: this.edit_info.pid,
                type: 1,
                content: "",
                content_turn: "",
                is_del: 2,
                is_privacy: 2,
                left_reserved: 0,
                right_reserved: 0,
                replace: '*',
                replace_type: 1,
              }
              this.toReload()
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    updateDo() {
      let content = this.edit_info.content
      if (this.edit_info.type === 2
          || this.edit_info.type === 3
          || this.edit_info.type === 4) {
        content = JSON.stringify(this.item_content)
      }
      this.$sa0.post({
        url: this.$api('编辑委托单字段'),
        data: {
          id: this.edit_info.id,
          name: this.edit_info.name,
          pid: this.edit_info.pid,
          type: this.edit_info.type,
          content: content,
          is_privacy: this.edit_info.is_privacy,
          left_reserved: this.edit_info.left_reserved,
          right_reserved: this.edit_info.right_reserved,
          replace: this.edit_info.replace,
          replace_type: this.edit_info.replace_type,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg(response.message)
            this.reload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveClick(type) {
      if (this.info.id === 0) {
        this.createDo(type)
      } else {
        this.updateDo()
      }
    },
    typeLabel() {
      switch (this.edit_info.type) {
        case 1:
          return '输入提示';
          break;
        case 2:
          return '下拉选项';
          break;
        case 3:
          return '多选选项';
          break;
        case 4:
          return '单选选项';
          break;
        case 5:
          return '输入提示';
          break;
        case 6:
          return '图片数量';
          break;
        case 7:
          return '时间类型';
          break;
      }
    },
    addItem() {
      this.item_content.push(this.item_input);
      this.item_input = '';
      this.$refs[`input${this.edit_info.type}`].focus()
    },
    delClick() {
      layer.confirm('是否删除？', {
        btn: ['确定', '取消'] //按钮
      }, () => {
        this.$sa0.post({
          url: this.$api('删除委托单字段'),
          data: {
            id: this.edit_info.id
          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              layer.closeAll()
              this.$router.push({
                params: {
                  id: this.edit_info.pid
                }
              })
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }, () => {

      });
    },
    toEdit() {
      this.edit()
    },
    itemUp(key) {
      let data = this.item_content[key];
      this.$set(this.item_content, key, this.item_content[key - 1])
      this.$set(this.item_content, key - 1, data)
    },
    itemDown(key) {
      let data = this.item_content[key];
      this.$set(this.item_content, key, this.item_content[key + 1])
      this.$set(this.item_content, key + 1, data)
    },
    itemDel(key) {
      this.item_content.splice(key, 1)
    },
  }
}
</script>
<template>
  <div>
    <div>
      <!--            <a-space>-->
      <!--                <div class="edit_title">{{ edit_info.id === 0 ? '创建新' : '编辑' }}字段</div>-->
      <!--                &lt;!&ndash;                <div v-if="edit_info.id !== 0" class="button_wrapper">&ndash;&gt;-->
      <!--                &lt;!&ndash;                    <a-space>&ndash;&gt;-->
      <!--                &lt;!&ndash;                        <a-button type="danger" @click="delClick()" class="ml-4">&ndash;&gt;-->
      <!--                &lt;!&ndash;                            删除&ndash;&gt;-->
      <!--                &lt;!&ndash;                        </a-button>&ndash;&gt;-->
      <!--                &lt;!&ndash;                    </a-space>&ndash;&gt;-->
      <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--                &lt;!&ndash;                <div v-if="edit_info.id === 0 && edit_info.pid !== 0" class="button_wrapper">&ndash;&gt;-->
      <!--                &lt;!&ndash;                    <a-button type="primary" @click="toEdit()" class="ml-4">&ndash;&gt;-->
      <!--                &lt;!&ndash;                        返回&ndash;&gt;-->
      <!--                &lt;!&ndash;                    </a-button>&ndash;&gt;-->
      <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--            </a-space>-->
      <div class="form_wrapper">
        <a-form-model :label-col="{span: 6}" :wrapper-col="{span: 18}">
          <div>
            <a-form-model-item label="名称">
              <a-input v-model="edit_info.name"/>
            </a-form-model-item>
            <a-form-model-item label="类型">
              <!--              1-input content - placeholder -->
              <!--              2-select content - ["选择1","选择2","选择3"] JSON字符串 -->
              <!--              3-checkbox content - ["选择1","选择2","选择3"] JSON字符串 -->
              <!--              4-radio content - ["选择1","选择2","选择3"] JSON字符串 -->
              <!--              5-textarea content - placeholder -->
              <!--              6-图片 图片最大数量-->
              <a-select style="width: 120px" v-model="edit_info.type">
                <a-select-option :value="1">输入框</a-select-option>
                <a-select-option :value="2">下拉框</a-select-option>
                <a-select-option :value="3">多选框</a-select-option>
                <a-select-option :value="4">单选框</a-select-option>
                <a-select-option :value="5">多行文本框</a-select-option>
                <a-select-option :value="6">图片</a-select-option>
                <a-select-option :value="7">时间输入框</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item :label="typeLabel()">
              <div v-if="edit_info.type === 1">
                <!--                输入框-->
                <a-input v-model="edit_info.content"/>
              </div>
              <div v-if="edit_info.type === 2">
                <!--                下拉框-->
                <div v-for="(item,key) in item_content">
                  <a-space>
                    <a-input class="item_input_wrapper" v-model="item_content[key]"/>
                    <a-button type="primary" v-if="key !== 0" @click="itemUp(key)">↑</a-button>
                    <a-button type="primary" v-if="key !== item_content.length - 1" @click="itemDown(key)">
                      ↓
                    </a-button>
                    <a-button type="danger" @click="itemDel(key)">删除</a-button>
                  </a-space>
                </div>
                <div class="display-flex">
                  <a-space>
                    <a-input @keyup.enter="addItem()" ref="input2" class="item_input_wrapper" v-model="item_input"/>
                    <a-button @click="addItem()" type="primary">添加</a-button>
                  </a-space>
                </div>
              </div>
              <div v-if="edit_info.type === 3">
                <!--                多选框-->
                <div v-for="(item,key) in item_content">
                  <a-space>
                    <a-input class="item_input_wrapper" v-model="item_content[key]"/>
                    <a-button type="primary" v-if="key !== 0" @click="itemUp(key)">↑</a-button>
                    <a-button type="primary" v-if="key !== item_content.length - 1" @click="itemDown(key)">
                      ↓
                    </a-button>
                    <a-button type="danger" @click="itemDel(key)">删除</a-button>
                  </a-space>
                </div>
                <div class="display-flex">
                  <a-space>
                    <a-input @keyup.enter="addItem()" ref="input3" class="item_input_wrapper" v-model="item_input"/>
                    <a-button @click="addItem()" type="primary">添加</a-button>
                  </a-space>
                </div>
              </div>
              <div v-if="edit_info.type === 4">
                <!--                单选框-->
                <div v-for="(item,key) in item_content">
                  <a-space>
                    <a-input class="item_input_wrapper" v-model="item_content[key]"/>
                    <a-button type="primary" v-if="key !== 0" @click="itemUp(key)">↑</a-button>
                    <a-button type="primary" v-if="key !== item_content.length - 1" @click="itemDown(key)">
                      ↓
                    </a-button>
                    <a-button type="danger" @click="itemDel(key)">删除</a-button>
                  </a-space>
                </div>
                <div class="display-flex">
                  <a-space>
                    <a-input @keyup.enter="addItem()" ref="input4" class="item_input_wrapper" v-model="item_input"/>
                    <a-button @click="addItem()" type="primary">添加</a-button>
                  </a-space>
                </div>
              </div>
              <div v-if="edit_info.type === 5">
                <!--                多行文本框-->
                <a-input v-model="edit_info.content"/>
              </div>
              <div v-if="edit_info.type === 6">
                <!--                图片-->
                <a-input v-model="edit_info.content"/>
              </div>
              <div v-if="edit_info.type === 7">
                <!--                图片-->
                <a-select v-model="edit_info.content">
                  <a-select-option value="0">日期</a-select-option>
                  <a-select-option value="1">时间</a-select-option>
                  <a-select-option value="2">日期+时间</a-select-option>
                </a-select>
              </div>
            </a-form-model-item>
            <a-form-model-item label="脱敏字段">
              <a-select v-model="edit_info.is_privacy">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="2">否</a-select-option>
              </a-select>
            </a-form-model-item>

            <div v-show="edit_info.is_privacy == 1">
              <a-form-model-item label="左保留位数">
                <a-input-number v-model="edit_info.left_reserved" :min="0"/>
              </a-form-model-item>

              <a-form-model-item label="右保留位数">
                <a-input-number v-model="edit_info.right_reserved" :min="0"/>
              </a-form-model-item>

              <a-form-model-item label="替换字符">
                <a-input v-model="edit_info.replace"/>
              </a-form-model-item>
              <a-form-model-item label="替换方式">
                <a-select v-model="edit_info.replace_type">
                  <a-select-option :value="1">完全替换</a-select-option>
                  <a-select-option :value="2">位数替换</a-select-option>
                </a-select>
                <div>
                  <div>脱敏前数据：
                    <a-input style="width: 100px;" v-model="replace_show"/>
                  </div>
                  <div>脱敏后数据：{{ replaceShow }}</div>
                </div>
              </a-form-model-item>
            </div>
          </div>

          <div style="margin-left: 60px">
            <a-button type="primary" @click="saveClick(1)" class="ml-4 ">
              保存
            </a-button>
            <a-button @click="saveClick(2)" class="ml-4 " v-if="edit_info.id === 0">
              保存并继续添加
            </a-button>
          </div>

        </a-form-model>
      </div>
    </div>
  </div>
</template>
<style scoped>
.item_input_wrapper {
  width: 200px;
}

.form_wrapper {
  width: 300px;
}

.button_wrapper {
  height: 40px;
  width: 700px;
  text-align: right;
}

.edit_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}
</style>
